<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="familyId">Family:</label>
        <Select2 name="familyId" v-model.number="familyId" :options="familyOptions"/>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="currencyId">Currency:</label>
        <Select2 name="currencyId" required v-model.number="currencyId" :options="currencyOptions"/>
      </div>
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="dateEnd">Date:</label>
        <input class="form-control" required type="date" v-model="dateEnd">
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" required type="submit" value="Show" :disabled="this.onRequest">
      </div>
    </div>
  </form>
  <div v-if="this.totalLiabilityEquities" class="row">
    <div class="col-lg-6 col-md-12">
      <div class="table-responsive">
        <table class="table table-borderless">
          <tr v-if="this.totalAsset"><th colspan="4" class="text-center">ASSET</th></tr>
          <tr v-if="this.datas['CASH_BANK']"><th colspan="4">Cash & Banks</th></tr>
          <tr v-for="data in this.datas['CASH_BANK']" :key="data">
            <td v-if="data.isTotal" colspan="2" class="text-bold">{{data.coaName}}</td>
            <td v-else></td>
            <td v-if="data.isTotal"></td>
            <td v-else>{{data.coaName}}</td>
            <td v-if="data.isTotal" class="text-right text-bold">{{this.formatNumberAcc(data.amount)}}</td>
            <td v-else class="text-right">{{this.formatNumberAcc(data.amount)}}</td>
          </tr>
          <tr v-if="this.datas['ACCOUNT_RECEIVABLE']"><th colspan="4">Account Receivable</th></tr>
          <tr v-for="data in this.datas['ACCOUNT_RECEIVABLE']" :key="data">
            <td v-if="data.isTotal" colspan="2" class="text-bold">{{data.coaName}}</td>
            <td v-else></td>
            <td v-if="data.isTotal"></td>
            <td v-else>{{data.coaName}}</td>
            <td v-if="data.isTotal" class="text-right text-bold">{{this.formatNumberAcc(data.amount)}}</td>
            <td v-else class="text-right">{{this.formatNumberAcc(data.amount)}}</td>
          </tr>
          <tr v-if="this.datas['OTHER_CURR']"><th colspan="4">Other Current Asset</th></tr>
          <tr v-for="data in this.datas['OTHER_CURR']" :key="data">
            <td v-if="data.isTotal" colspan="2" class="text-bold">{{data.coaName}}</td>
            <td v-else></td>
            <td v-if="data.isTotal"></td>
            <td v-else>{{data.coaName}}</td>
            <td v-if="data.isTotal" class="text-right text-bold">{{this.formatNumberAcc(data.amount)}}</td>
            <td v-else class="text-right">{{this.formatNumberAcc(data.amount)}}</td>
          </tr>
          <tr v-if="this.datas['FIXED_ASSET']"><th colspan="4">Fixed Asset</th></tr>
          <tr v-for="data in this.datas['FIXED_ASSET']" :key="data">
            <td v-if="data.isTotal" colspan="2" class="text-bold">{{data.coaName}}</td>
            <td v-else></td>
            <td v-if="data.isTotal"></td>
            <td v-else>{{data.coaName}}</td>
            <td v-if="data.isTotal" class="text-right text-bold">{{this.formatNumberAcc(data.amount)}}</td>
            <td v-else class="text-right">{{this.formatNumberAcc(data.amount)}}</td>
          </tr>
          <tr v-if="this.datas['ACCUMULATED_DEPRECIATION']"><th colspan="4">Accumulated Depreciation</th></tr>
          <tr v-for="data in this.datas['ACCUMULATED_DEPRECIATION']" :key="data">
            <td v-if="data.isTotal" colspan="2" class="text-bold">{{data.coaName}}</td>
            <td v-else></td>
            <td v-if="data.isTotal"></td>
            <td v-else>{{data.coaName}}</td>
            <td v-if="data.isTotal" class="text-right text-bold">{{this.formatNumberAcc(data.amount)}}</td>
            <td v-else class="text-right">{{this.formatNumberAcc(data.amount)}}</td>
          </tr>
          <tr v-if="this.datas['OTHER_ASSET']"><th colspan="4">Other Asset</th></tr>
          <tr v-for="data in this.datas['OTHER_ASSET']" :key="data">
            <td v-if="data.isTotal" colspan="2" class="text-bold">{{data.coaName}}</td>
            <td v-else></td>
            <td v-if="data.isTotal"></td>
            <td v-else>{{data.coaName}}</td>
            <td v-if="data.isTotal" class="text-right text-bold">{{this.formatNumberAcc(data.amount)}}</td>
            <td v-else class="text-right">{{this.formatNumberAcc(data.amount)}}</td>
          </tr>
          <tr v-if="this.totalAsset"><th colspan="3">{{this.totalAsset.name}}</th><th class="text-right">{{this.formatNumberAcc(this.totalAsset.amount)}}</th></tr>
        </table>
      </div>
    </div>
    <div class="col-lg-6 col-md-12">
      <div class="table-responsive">
        <table class="table table-borderless">
          <tr v-if="this.totalLiabilityEquities"><th colspan="4" class="text-center">LIABILITIES AND EQUITIES</th></tr>
          <tr v-if="this.totalLiabilities"><th colspan="4" class="text-center">LIABILITIES</th></tr>
          <tr v-if="this.datas['ACCOUNT_PAYABLE']"><th colspan="4">Account Payable</th></tr>
          <tr v-for="data in this.datas['ACCOUNT_PAYABLE']" :key="data">
            <td v-if="data.isTotal" colspan="2" class="text-bold">{{data.coaName}}</td>
            <td v-else></td>
            <td v-if="data.isTotal"></td>
            <td v-else>{{data.coaName}}</td>
            <td v-if="data.isTotal" class="text-right text-bold">{{this.formatNumberAcc(data.amount)}}</td>
            <td v-else class="text-right">{{this.formatNumberAcc(data.amount)}}</td>
          </tr>
          <tr v-if="this.datas['SHORT_TERM_OBLIGATION']"><th colspan="4">Short Term Obligation</th></tr>
          <tr v-for="data in this.datas['SHORT_TERM_OBLIGATION']" :key="data">
            <td v-if="data.isTotal" colspan="2" class="text-bold">{{data.coaName}}</td>
            <td v-else></td>
            <td v-if="data.isTotal"></td>
            <td v-else>{{data.coaName}}</td>
            <td v-if="data.isTotal" class="text-right text-bold">{{this.formatNumberAcc(data.amount)}}</td>
            <td v-else class="text-right">{{this.formatNumberAcc(data.amount)}}</td>
          </tr>
          <tr v-if="this.datas['LONG_TERM_OBLIGATION']"><th colspan="4">Long Term Obligation</th></tr>
          <tr v-for="data in this.datas['LONG_TERM_OBLIGATION']" :key="data">
            <td v-if="data.isTotal" colspan="2" class="text-bold">{{data.coaName}}</td>
            <td v-else></td>
            <td v-if="data.isTotal"></td>
            <td v-else>{{data.coaName}}</td>
            <td v-if="data.isTotal" class="text-right text-bold">{{this.formatNumberAcc(data.amount)}}</td>
            <td v-else class="text-right">{{this.formatNumberAcc(data.amount)}}</td>
          </tr>
          <tr v-if="this.totalLiabilities"><th colspan="3">{{this.totalLiabilities.name}}</th><th class="text-right">{{this.formatNumberAcc(this.totalLiabilities.amount)}}</th></tr>
          <tr v-if="this.totalEquities"><th colspan="4" class="text-center">EQUITIES</th></tr>
          <tr v-if="this.datas['CAPITAL']"><th colspan="4">Capital</th></tr>
          <tr v-for="data in this.datas['CAPITAL']" :key="data">
            <td v-if="data.isTotal" colspan="2" class="text-bold">{{data.coaName}}</td>
            <td v-else></td>
            <td v-if="data.isTotal"></td>
            <td v-else>{{data.coaName}}</td>
            <td v-if="data.isTotal" class="text-right text-bold">{{this.formatNumberAcc(data.amount)}}</td>
            <td v-else class="text-right">{{this.formatNumberAcc(data.amount)}}</td>
          </tr>
          <tr v-if="this.totalEquities"><th colspan="3">{{this.totalEquities.name}}</th><th class="text-right">{{this.formatNumberAcc(this.totalEquities.amount)}}</th></tr>
          <tr v-if="this.totalLiabilityEquities"><th colspan="3">{{this.totalLiabilityEquities.name}}</th><th class="text-right">{{this.formatNumberAcc(this.totalLiabilityEquities.amount)}}</th></tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Select2 from 'vue3-select2-component';
  import moment from 'moment';
  import {formatNumberAcc} from '../_helpers/calculation';
  export default {
    computed: {
      ...mapState('families', ['families']),
      ...mapState('users', ['user', 'profile']),
      ...mapState('currencies', ['currencies']),
      ...mapState('reports', ['reports', 'onRequest']),
    },
    data(){
      return {        
        familyOptions: [],
        currencyOptions: [],
        datas: [],
        familyId: null,
        currencyId: null,
        totalAsset: null,
        totalLiabilities: null,
        totalEquities: null,
        totalLiabilityEquities: null,
        dateEnd: moment().format("YYYY-MM-DD"),
      }
    },
    components:{
      Select2,
    },
    methods: {
      ...mapActions('reports', {get_data: 'get_balance_sheet'}),
      ...mapActions('currencies', {get_currencies: 'get_all'}),
      ...mapActions('families', ['get_all']),
      ...mapActions('alert', ['error', 'clear']),
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        this.totalAsset= null
        this.totalLiabilities= null
        this.totalEquities= null
        this.totalLiabilityEquities= null
        const {familyId, dateEnd, currencyId} = this
        let end = moment(dateEnd).endOf('day').format('x')
        this.get_data({familyId, dateEnd: end, currencyId})
      },
      formatNumberAcc(x){
        return formatNumberAcc(x, 2)
      },
      summaries(){
        let total = 0;
        total += this.calculate('CASH_BANK', 'Total Cash & Banks');
        total += this.calculate('ACCOUNT_RECEIVABLE', 'Total Account Receivable');
        total += this.calculate('OTHER_CURR', 'Total Other Current Asset');
        total += this.calculate('FIXED_ASSET', 'Total Fixed Asset');
        total += this.calculate('ACCUMULATED_DEPRECIATION', 'Total Accumulated Depreciation');
        total += this.calculate('OTHER_ASSET', 'Total Other Asset');
        this.totalAsset = {amount: total, name: 'TOTAL ASSET'}
        let totalLiability = 0;
        totalLiability += this.calculate('ACCOUNT_PAYABLE', 'Total Account Payable');
        totalLiability += this.calculate('SHORT_TERM_OBLIGATION', 'Total Short Term Obligation');
        totalLiability += this.calculate('LONG_TERM_OBLIGATION', 'Total Long Term Obligation');
        this.totalLiabilities = {amount: totalLiability, name: 'TOTAL LIABILITIES'}
        let totalEquity = 0;
        let objEquity = {amount: 0, coaName: 'Total Capital', isTotal: true}
        if(this.datas['CAPITAL']){
          this.datas['CAPITAL'] = this.datas['CAPITAL'].sort(function(a, b){
            if ( a.coaCode < b.coaCode ){
              return -1;
            }
            if ( a.coaCode > b.coaCode ){
              return 1;
            }
            return 0;
          })
          for(let i = 0;i<this.datas['CAPITAL'].length;i++){
            objEquity.amount+=this.datas['CAPITAL'][i].amount
            totalEquity += this.datas['CAPITAL'][i].amount
          }
        } else {
          this.datas['CAPITAL'] = []
        }
        let currentProfit = total - totalEquity - totalLiability
        let objProfit = {amount: currentProfit, coaName: ('Current Profit' + (currentProfit > 0 ? '' : ' (Loss)'))}
        this.datas['CAPITAL'].push(objProfit)
        objEquity.amount+=currentProfit
        totalEquity+=currentProfit
        this.datas['CAPITAL'].push(objEquity)
        this.totalEquities = {amount: totalEquity, name: 'TOTAL EQUITIES'}
        this.totalLiabilityEquities = {amount: totalEquity + totalLiability, name: 'TOTAL LIABILITIES & EQUITIES'}

      },
      calculate(NAME, coaName){        
        let obj = {amount: 0, coaName: coaName, isTotal: true}
        if(this.datas[NAME]){
          this.datas[NAME] = this.datas[NAME].sort(function(a, b){
            if ( a.coaCode < b.coaCode ){
              return -1;
            }
            if ( a.coaCode > b.coaCode ){
              return 1;
            }
            return 0;
          })
          for(let i = 0;i<this.datas[NAME].length;i++){
            obj.amount+=this.datas[NAME][i].amount
          }
          this.datas[NAME].push(obj)
        }
        return obj.amount;
      }
    },
    created() {
      this.$emit('onChildInit', 'Balance Sheets')
      this.get_all()
      this.get_currencies()
      if(this.profile && this.profile.familyId && this.profile.currencyId){
        this.familyId = this.profile.familyId
        this.currencyId = this.profile.currencyId
        this.handleSubmit()
      }
    },
    watch: {
      families(nextState, prevState){
        if(nextState !== prevState){
          this.familyOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.familyOptions.push({id:item.id, text:item.familyName})
            })
          }
          return
        }
      },
      currencies(nextState, prevState){
        if(nextState !== prevState){
          this.currencyOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.currencyOptions.push({id:item.id, text:item.currencyCode + ' - ' + item.currencyName})
            })
          }
          return
        }
      },
      reports(nextState, prevState){
        if(nextState !== prevState){
          if(nextState.length > 0){
            this.datas = []
            nextState.map(item => {
              if(!this.datas[item.coaCategory]){
                this.datas[item.coaCategory] = []
              }
              this.datas[item.coaCategory].push(item)
            })
            this.summaries()
          }
          return
        }
      },
    }
  }
</script>
