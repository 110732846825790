<template>
  <Datatable v-bind:entries="users" :columns="columns" :title="'Users'"/>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import { getCapability } from '../_helpers'
  import Datatable from '../components/Datatable.vue'
  export default {
    computed: {
      ...mapState('users', ['users', 'user']),
      columns(){
        return [
          {name: 'username', text: 'User Name'},
          {name: 'email', text: 'Email'},
          {name: 'family', text: 'Family',
            raw: {
              type: 'Sub',
              value: 'familyName'
            }
          },
          {name: 'status', text: 'Status'},
          {name: 'lastLogin', text: 'Last Login',
            raw: {
              type: 'Moment_UTC',
            }
          },
          {name: 'createdBy', text: 'Created By'},
          {name: 'createdDate', text: 'Created At', raw: { type: 'Moment_UTC'}},
          {name: 'modifiedBy', text: 'Modified By'},
          {name: 'modifiedDate', text: 'Modified At', raw: { type: 'Moment_UTC'}},
          {name: 'action', text: 'Action',
            raw: {
              type: 'Action',
              fields: [
                getCapability('USER_EDIT', this.user) ? {event: 'edit_user', text: 'Edit', method: 'get', class: 'btn btn-primary'} : {},
                getCapability('USER_EDIT', this.user) ? {event: 'edit_user_capability', text: 'Edit Role', method: 'get', class: 'btn btn-warning ml-1'} : {},
                getCapability('USER_DELETE', this.user) ? {event: 'users/destroy', text: 'Delete', method: 'post', class: 'btn btn-danger ml-1'} : {},
              ]
            }
          },
        ]
      }
    },
    components:{
      Datatable,
    },
    methods: {
      ...mapActions('users', ['get_all']),
    },
    created() {
      this.$emit('onChildInit', 'Users', getCapability('USER_ADD', this.user) ? '/users/add' : '')
      this.get_all()
    },
  }
</script>
