<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 required">
        <label for="familyId">Family:</label>
        <Select2 name="familyId" required v-model="transaction.familyId" :options="familyOptions" @change="onChange($event)" @select="onChange($event)"/>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="currencyId">Currency:</label>
        <Select2 name="currencyId" required v-model.number="transaction.currencyId" :options="currencyOptions"/>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="transactionDate">Date:</label>
        <input class="form-control" required type="date" v-model="transaction.transactionDate">
      </div>
      <div class="form-group col-sm-12">
        <label for="transactionTemplateId">Use Template:</label>
        <Select2 name="transactionTemplateId" v-model="transactionTemplateId" :options="transactionTemplateOptions" @change="onChangeTemplate($event)" @select="onChangeTemplate($event)"/>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="transactionName">Transaction Name:</label>
        <input class="form-control" required="" name="transactionName" type="text" v-model="transaction.transactionName">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="transactionAmount">Amount:</label>
        <input class="form-control" required="" name="transactionAmount" type="text" v-model="transaction_amount_txt">
      </div>
      <div class="form-group col-sm-12">
        <label for="transactionDescription">Transaction Description:</label>
        <textarea class="form-control" name="transactionDescription" v-model="transaction.transactionDescription"></textarea>
      </div>      
      <div class="col-sm-12" style="border-top: 1px solid black;padding-top: 20px;margin-top: 20px;margin-bottom: 20px;">
        <div class="row">
          <div class="form-group col-sm-12">
            <h4>Add Detail COA</h4>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-sm-12">
            <label for="coaId">COA:</label>
            <Select2 name="coaId" v-model="coaId" :options="coaOptions"/>
          </div>
          <div class="form-group col-sm-12">
            <label for="recordType">Type:</label>
            <select class="form-control" name="recordType" v-model="recordType">
              <option value="DR" style="color:black;">DR</option>
              <option value="CR" style="color:black;">CR</option>
            </select>
          </div>
          <div class="form-group col-sm-12">
            <button v-if="this.isEdit == false" class="btn btn-success" @click.prevent="clickAdd($event)">Add</button>
            <button v-else class="btn btn-success" @click.prevent="clickAdd($event)">Update</button>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <table class="table">
              <thead>
                <tr>
                  <th>COA Code</th>
                  <th>COA Name</th>
                  <th>Category</th>
                  <th>Record Type</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tr v-for="(item, index) in transaction.transactionCoas" :key="index">
                <td>{{item.coa.coaCode}}</td>
                <td>{{item.coa.coaName}}</td>
                <td>{{item.coa.coaCategory}}</td>
                <td>{{item.recordType}}</td>
                <td>                  
                  <button class="btn btn-sm btn-info" @click="clickEdit($event, index)">Edit</button>
                  <button class="btn btn-sm btn-danger ml-1" @click="clickRemove($event, index)">Remove</button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/transactions">Cancel</router-link>
      </div>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Select2 from 'vue3-select2-component'
  import moment from 'moment'
  import {formatNumber} from '../_helpers/calculation';
  export default {
    computed: {
      ...mapState('families', ['families']),
      ...mapState('coas', ['coas']),
      ...mapState('users', ['user', 'profile']),
      ...mapState('transactions', ['onRequest']),
      ...mapState('transaction_templates', ['transaction_templates']),
      ...mapState('currencies', ['currencies']),
      transaction_amount_txt:{
        get() {
          return this.transaction && this.transaction.transactionAmount ? formatNumber(this.transaction.transactionAmount) : ""
        },
        set(value) {
          this.transaction.transactionAmount = Number(value.replaceAll(',',''))
        }
      }
    },
    components: {
      Select2
    },
    data(){
      return {
        transaction: {
          familyId: null,
          currencyId: null,
          transactionName: null,
          transactionDescription: null,
          transactionDate: moment().format("YYYY-MM-DD"),
          transactionAmount: null,
          transactionCoas: [],
        },
        isEdit: false,
        editIndex: null,
        coaId: null,
        recordType: 'DR',
        familyOptions: [],
        coaOptions: [],
        transactionTemplateId: null,
        transactionTemplateOptions: [],
        currencyOptions: [],
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.clear();
        const {familyId, currencyId, transactionName, transactionDescription, transactionDate, transactionAmount, transactionCoas} = this.transaction
        if(transactionCoas.length < 2){
          this.error('Minimum 2 COA are required to Create Transactions')
          return;
        }
        if(transactionCoas.filter(item => item.recordType == "DR").length != transactionCoas.filter(item => item.recordType == "CR").length){
          this.error('Transaction will not be balanced.')
          return;
        }
        let date = moment(transactionDate).startOf('day')
        this.add({ familyId, currencyId, transactionName, transactionDescription, transactionDate: date, transactionAmount, transactionCoas })
      },
      onChange(event){
        this.coaId = null
        this.transaction.transactionName = null
        this.transaction.transactionDescription = null
        this.transaction.transactionAmount = null
        this.transaction.transactionCoas = []
        this.resetData()
        this.get_coas({familyId: parseInt(event.id)})
        this.get_transaction_templates({familyId: parseInt(event.id)})
      },
      onChangeTemplate(event){
        let transactionTemplate = this.transactionTemplateOptions.filter(item => item.id == parseInt(event.id))
        if(transactionTemplate.length < 1){
          return
        }
        this.transaction.transactionCoas = []
        this.transaction.transactionName = transactionTemplate[0].text
        transactionTemplate[0].transactionTemplateCoas.map(item2 => {
          let obj = {
            coa: item2.coa,
            recordType: item2.recordType,
          }
          if(this.transaction.transactionCoas.filter(item => item.coa.id == obj.coa.id).length > 0){   
            return;
          }
          this.transaction.transactionCoas.push(obj)
        })
      },
      ...mapActions('families', ['get_all']),
      ...mapActions('coas', {get_coas: 'get_all'}),
      ...mapActions('transactions', ['add']),
      ...mapActions('alert', ['error', 'clear']),
      ...mapActions('transaction_templates', {get_transaction_templates: 'get_all'}),
      ...mapActions('currencies', {get_currencies: 'get_all'}),
      clickAdd(){
        if(this.coaId === null || this.recordType == null){
          return;
        }
        let coa = this.coaOptions.filter(item => item.id == this.coaId)
        if(coa.length < 1){
          return
        }
        let obj = {
          coa: coa[0].coa,
          recordType: this.recordType,
        }
        if(this.isEdit){
          this.transaction.transactionCoas[this.editIndex] = obj
        } else {
          if(this.transaction.transactionCoas.filter(item => item.coa.id == this.coaId).length > 0){     
            return;
          }
          this.transaction.transactionCoas.push(obj)
        }
        
        this.resetData()
      },      
      clickEdit(event, index){
        event.preventDefault()
        const {coa, recordType} = this.transaction.transactionCoas[index]
        this.coaId = coa.id
        this.recordType = recordType
        this.transactionTemplateId = null
        this.isEdit = true
        this.editIndex = index
      },
      clickRemove(event, index){
        event.preventDefault()
        this.transaction.transactionCoas.splice(index, 1)
        this.resetData()
      },
      resetData(){
        this.isEdit= false
        this.editIndex= null
        this.transactionTemplateId= null
        this.coaId= null
        this.recordType= "DR"
      },
    },
    created() {
      this.$emit('onChildInit', 'Add New Transaction')
      this.get_all()
      this.get_currencies()
      if(this.profile && this.profile.familyId){
        this.transaction.currencyId = this.profile.currencyId
        this.transaction.familyId = this.profile.familyId
        this.get_transaction_templates({familyId: this.transaction.familyId})
        this.get_coas({familyId: this.transaction.familyId})
      }
    },
    watch: {
      families(nextState, prevState){
        if(nextState !== prevState){
          this.familyOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.familyOptions.push({id:item.id, text:item.familyName})
            })
          }
          return
        }
      },
      coas(nextState, prevState){
        if(nextState !== prevState){
          this.coaOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.coaOptions.push({id:item.id, text:item.coaCode + ' - ' + item.coaName, coa: item})
            })    
            this.coaOptions = this.coaOptions.sort(function(a, b){
              if ( a.text < b.text ){
                return -1;
              }
              if ( a.text > b.text ){
                return 1;
              }
              return 0;
            })
          }
          return
        }
      },
      transaction_templates(nextState, prevState){
        if(nextState !== prevState){
          this.transactionTemplateOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.transactionTemplateOptions.push({id:item.id, text:item.transactionTemplateName, transactionTemplateCoas: item.transactionTemplateCoas, currencyId: item.currencyId})
            })
          }
          return
        }
      },
      currencies(nextState, prevState){
        if(nextState !== prevState){
          this.currencyOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.currencyOptions.push({id:item.id, text:item.currencyCode + ' - ' + item.currencyName})
            })
          }
          return
        }
      },
    }
  }
</script>
