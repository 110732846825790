<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div v-if="u.family" class="form-group col-sm-12">
          <label for="familyId">Family:</label>
          <input class="form-control" disabled="" name="familyId" type="text" v-model="u.family.familyName">
      </div>
      <div class="form-group col-sm-12 required">
          <label for="username">User Name:</label>
          <input class="form-control" required="" name="username" type="text" v-model="u.username">
      </div>
      <div class="form-group col-sm-12">
        <label for="email">Email:</label>
        <input class="form-control" disabled="" name="email" type="email" v-model="u.email">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="status">Status:</label>
        <select class="form-control" required name="status" v-model="u.status">
          <option value="STATUS_ACTIVE" style="color:black;">Active</option>
          <option value="STATUS_INACTIVE" style="color:black;">Inactive</option>
        </select>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="currencyId">Default Currency:</label>
        <Select2 name="currencyId" required v-model.number="u.currencyId" :options="currencyOptions"/>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/users">Cancel</router-link>
      </div>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Select2 from 'vue3-select2-component'
  export default {
    computed: {
        ...mapState('users', ['u', 'onRequest']),
        ...mapState('currencies', ['currencies']),
    },
    components: {
      Select2
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.edit(this.u)
      },
      ...mapActions('users', ['edit', 'get']),
      ...mapActions('currencies', {get_currencies: 'get_all'}),
    },
    data(){
      return {
        currencyOptions: [],
      }
    },
    created() {
      this.$emit('onChildInit', 'Edit User')
      this.get_currencies()
      this.get(this.$route.params.id)
    },
    watch: {
      currencies(nextState, prevState){
        if(nextState !== prevState){
          this.currencyOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.currencyOptions.push({id:item.id, text:item.currencyCode + ' - ' + item.currencyName})
            })
          }
          return
        }
      },
    }
  }
</script>
