<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="familyId">Family:</label>
        <Select2 name="familyId" required v-model.number="familyId" :options="familyOptions" @change="onChange($event)" @select="onChange($event)"/>
      </div>
        <div class="form-group col-sm-12 required">
          <label for="coaId">COA:</label>
          <Select2 name="coaId" required v-model.number="coaId" :options="coaOptions"/>
        </div>
      <div class="form-group col-sm-12 required">
        <label for="currencyId">Currency:</label>
        <Select2 name="currencyId" required v-model.number="currencyId" :options="currencyOptions"/>
      </div>
      <div class="form-group col-sm-12 col-md-6 required">
        <label for="dateStart">From Date:</label>
        <input class="form-control" required type="date" v-model="dateStart">
      </div>
      <div class="form-group col-sm-12 col-md-6 required">
        <label for="dateEnd">To Date:</label>
        <input class="form-control" required type="date" v-model="dateEnd">
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" required type="submit" value="Show" :disabled="this.onRequest">
      </div>
    </div>
  </form>
  <Datatable v-if="reportLedgers" v-bind:entries="reportLedgers" :columns="columns" :title="'Ledgers'"/>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Datatable from '../components/Datatable.vue'
  import Select2 from 'vue3-select2-component';
  import moment from 'moment';
  export default {
    computed: {
      ...mapState('families', ['families']),
      ...mapState('coas', ['coas']),
      ...mapState('users', ['user', 'profile']),
      ...mapState('reports', ['reportLedgers', 'onRequest']),
      ...mapState('currencies', ['currencies']),
      columns(){
        return [
            {name: 'transactionDate', text: 'Date', order: 'desc', raw: {type: 'Moment_date'}},
            {name: 'transactionName', text: 'Transaction'},
            {name: 'amount', text: 'Amount', raw: {type: 'format_number_acc'}, class: 'text-right'},
            {name: 'transactionRecordType', text: 'Text'},
          ]
        }
    },
    data(){
      return {        
        familyOptions: [],
        familyId: null,
        dateStart: moment().startOf('month').format("YYYY-MM-DD"),
        dateEnd: moment().endOf('month').format("YYYY-MM-DD"),
        coaOptions: [],
        coaId: null,
        currencyId: null,
        currencyOptions: [],
      }
    },
    components:{
      Datatable,
      Select2,
    },
    methods: {
      ...mapActions('reports', {get_data: 'get_ledgers'}),
      ...mapActions('currencies', {get_currencies: 'get_all'}),
      ...mapActions('families', ['get_all']),
      ...mapActions('alert', ['error', 'clear']),
      ...mapActions('coas', {get_coas: 'get_all'}),
      handleSubmit(){
        if(this.onRequest || this.coaId == null) return;
        this.clear()
        const {familyId, dateStart, dateEnd, coaId, currencyId} = this
        let start = moment(dateStart).startOf('day').format('x')
        let end = moment(dateEnd).endOf('day').format('x')
        this.get_data({familyId, dateStart:start, dateEnd:end, coaId, currencyId})
      },
      onChange(event){
        this.coaId = null
        this.get_coas({familyId: parseInt(event.id)})
      },
    },
    created() {
      this.$emit('onChildInit', 'Ledger')
      this.get_all()
      this.get_currencies()
      if(this.profile && this.profile.familyId){
        this.familyId =  this.profile.familyId
        this.currencyId = this.profile.currencyId
        this.get_coas({familyId: this.familyId})
      }
    },
    watch: {
      families(nextState, prevState){
        if(nextState !== prevState){
          this.familyOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.familyOptions.push({id:item.id, text:item.familyName})
            })
          }
          return
        }
      },
      currencies(nextState, prevState){
        if(nextState !== prevState){
          this.currencyOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.currencyOptions.push({id:item.id, text:item.currencyCode + ' - ' + item.currencyName})
            })
          }
          return
        }
      },
      coas(nextState, prevState){
        if(nextState !== prevState){
          this.coaOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.coaOptions.push({id:item.id, text:item.coaCode + ' - ' + item.coaName, coa: item})
            })    
            this.coaOptions = this.coaOptions.sort(function(a, b){
              if ( a.text < b.text ){
                return -1;
              }
              if ( a.text > b.text ){
                return 1;
              }
              return 0;
            })
          }
          return
        }
      },
    }
  }
</script>
