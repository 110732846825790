<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 required">
          <label for="familyId">Family:</label>
        <Select2 name="familyId" required v-model.number="familyId" :options="familyOptions"/>
      </div>
      <div class="form-group col-sm-12 required">
          <label for="username">User Name:</label>
          <input class="form-control" required="" name="username" type="text" v-model="username">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="email">Email:</label>
        <input class="form-control" required="" name="email" type="email" v-model="email">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="contact_phone">Password:</label>
        <input class="form-control" name="password" type="text" v-model="password">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="status">Status:</label>
        <select class="form-control" name="status" v-model="status">
          <option value="STATUS_ACTIVE" style="color:black;">Active</option>
          <option value="STATUS_INACTIVE" style="color:black;">Inactive</option>
        </select>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="currencyId">Default Currency:</label>
        <Select2 name="currencyId" required v-model.number="currencyId" :options="currencyOptions"/>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/users">Cancel</router-link>
      </div>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Select2 from 'vue3-select2-component'
  export default {
    computed: {
        ...mapState('families', ['families']),
        ...mapState('users', ['user', 'onRequest', 'profile']),
        ...mapState('currencies', ['currencies']),
    },
    components: {
      Select2
    },
    data(){
      return {
        username: '',
        email: '',
        password: '',
        familyId: '',
        status: 'STATUS_ACTIVE',
        familyOptions: [],
        currencyId: null,
        currencyOptions: [],
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        const {username, email, password, familyId, status, currencyId} = this
        this.add({ username, email, password, familyId, status, currencyId })
      },
      ...mapActions('families', ['get_all']),
      ...mapActions('currencies', {get_currencies: 'get_all'}),
      ...mapActions('users', ['add']),
    },
    created() {
      this.$emit('onChildInit', 'Add New User')
      this.get_all()
      this.get_currencies()
      this.familyId = this.profile ? this.profile.familyId : null
      this.currencyId = this.profile ? this.profile.currencyId : null
    },
    watch: {
      families(nextState, prevState){
        if(nextState !== prevState){
          this.familyOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.familyOptions.push({id:item.id, text:item.familyName})
            })
          }
          return
        }
      },
      currencies(nextState, prevState){
        if(nextState !== prevState){
          this.currencyOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.currencyOptions.push({id:item.id, text:item.currencyCode + ' - ' + item.currencyName})
            })
          }
          return
        }
      },
    }
  }
</script>
