<template>
  <form @submit.prevent="handleUpdate">
    <div class="row">
      <div class="form-group col-sm-12 required">
          <label for="username">Name:</label>
          <input class="form-control" required="" name="username" type="text" v-model="user.username">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="email">Email:</label>
        <input class="form-control" required="" name="email" type="email" v-model="user.email">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="currencyId">Currency:</label>
        <Select2 name="currencyId" required v-model="currencyId" :options="currencyOptions"/>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
      </div>
    </div>
  </form>
  <form @submit.prevent="handleChangePassword">
    <div class="row">
      <div class="form-group col-sm-6 required">
          <label for="password">Password:</label>
          <input class="form-control" required="" name="password" type="password" v-model="password">
      </div>
      <div class="form-group col-sm-6 required">
        <label for="repassword">Repeat Password:</label>
        <input class="form-control" required="" name="repassword" type="password" v-model="repassword">
      </div>
      <div class="form-group col-sm-12">
          <input class="btn btn-warning" type="submit" value="Change Password">
      </div>
    </div>
 </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Select2 from 'vue3-select2-component'
  export default {
    data(){
      return {
        password: '',
        repassword: '',
        currencyId: null,
        currencyOptions: [],
      }
    },
    computed: {
      ...mapState('users', ['user', 'profile', 'onRequest']),
      ...mapState('currencies', ['currencies']),
    },
    methods: {
      handleUpdate(){
        if(this.onRequest) return;
        const {user} = this
        if (user) {
            user.currencyId = this.currencyId
            this.edit_profile({ user })
        }
      },
      handleChangePassword(){
        if(this.onRequest) return;
        const {password, repassword} = this
        if (password && repassword) {
            this.change_password({ password, repassword })
        }
      },
      ...mapActions('users', ['edit_profile', 'change_password']),
      ...mapActions('currencies', {get_currencies: 'get_all'}),
    },
    components: {
      Select2
    },
    created() {
      this.$emit('onChildInit', 'Edit Profile')
      this.get_currencies()
      if(this.profile){
        this.currencyId = this.profile.currencyId
      }
    },
    watch:{      
      currencies(nextState, prevState){
        if(nextState !== prevState){
          this.currencyOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.currencyOptions.push({id:item.id, text:item.currencyCode + ' - ' + item.currencyName})
            })
          }
          return
        }
      },
    }
  }
</script>
