<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 required">
        <label for="familyId">Family:</label>
        <Select2 name="familyId" required v-model="transactionTemplate.familyId" :options="familyOptions" @change="onChange($event)" @select="onChange($event)"/>
      </div>
      <div class="form-group col-sm-12 required">
          <label for="transactionTemplateName">Template Transaction Name:</label>
          <input class="form-control" required="" name="transactionTemplateName" type="text" v-model="transactionTemplate.transactionTemplateName">
      </div>
      <div class="col-sm-12" style="border-top: 1px solid black;padding-top: 20px;margin-top: 20px;margin-bottom: 20px;">
        <div class="row">
          <div class="form-group col-sm-12">
            <h4>Add Detail COA</h4>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-sm-12 required">
            <label for="coaId">COA:</label>
            <Select2 name="coaId" v-model="coaId" :options="coaOptions"/>
          </div>
          <div class="form-group col-sm-12 required">
            <label for="recordType">Type:</label>
            <select class="form-control" name="recordType" v-model="recordType">
              <option value="DR" style="color:black;">DR</option>
              <option value="CR" style="color:black;">CR</option>
            </select>
          </div>
          <div class="form-group col-sm-12">
            <button v-if="this.isEdit == false" class="btn btn-success" @click.prevent="clickAdd($event)">Add</button>
            <button v-else class="btn btn-success" @click.prevent="clickAdd($event)">Update</button>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <table class="table">
              <thead>
                <tr>
                  <th>COA Code</th>
                  <th>COA Name</th>
                  <th>Category</th>
                  <th>Record Type</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tr v-for="(item, index) in transactionTemplate.transactionTemplateCoas" :key="index">
                <td>{{item.coa.coaCode}}</td>
                <td>{{item.coa.coaName}}</td>
                <td>{{item.coa.coaCategory}}</td>
                <td>{{item.recordType}}</td>
                <td>                  
                  <button class="btn btn-sm btn-info" @click="clickEdit($event, index)">Edit</button>
                  <button class="btn btn-sm btn-danger ml-1" @click="clickRemove($event, index)">Remove</button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/transaction_templates">Cancel</router-link>
      </div>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Select2 from 'vue3-select2-component'
  export default {
    computed: {
      ...mapState('families', ['families']),
      ...mapState('coas', ['coas']),
      ...mapState('users', ['user', 'profile']),
      ...mapState('transaction_templates', ['onRequest']),
    },
    components: {
      Select2
    },
    data(){
      return {
        transactionTemplate:{
          familyId: null,
          transactionTemplateName: null,
          transactionTemplateCoas: [],
        },
        familyOptions: [],
        coaOptions: [],
        isEdit: false,
        editIndex: null,
        coaId: null,
        recordType: "DR",
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        const {familyId, transactionTemplateName, transactionTemplateCoas} = this.transactionTemplate
        if(transactionTemplateCoas.length < 1){
          this.error('Minimum 1 COA are required to Create Template')
          return;
        }
        this.add({ familyId, transactionTemplateName, transactionTemplateCoas})
      },
      onChange(event){
        this.coaId = null
        this.coaOptions= []
        this.get_coas({familyId: parseInt(event.id)})
      },
      ...mapActions('families', ['get_all']),
      ...mapActions('coas', {get_coas: 'get_all'}),
      ...mapActions('transaction_templates', ['add']),
      ...mapActions('alert', ['error', 'clear']),
      clickAdd(){
        if(this.coaId === null || this.recordType == null){
          return;
        }
        let coa = this.coaOptions.filter(item => item.id == this.coaId)
        if(coa.length < 1){
          return
        }
        let obj = {
          coa: coa[0].coa,
          recordType: this.recordType,
        }
        if(this.isEdit){
          this.transactionTemplate.transactionTemplateCoas[this.editIndex] = obj
        } else {
          if(this.transactionTemplate.transactionTemplateCoas.filter(item => item.coa.id == this.coaId).length > 0){   
            this.error('Please remove similar COA before.')         
            return;
          }
          this.transactionTemplate.transactionTemplateCoas.push(obj)
        }
        this.resetData()
      },      
      clickEdit(event, index){
        event.preventDefault()
        const {coa, recordType} = this.transactionTemplate.transactionTemplateCoas[index]
        this.coaId = coa.id
        this.recordType = recordType
        this.isEdit = true
        this.editIndex = index
      },
      clickRemove(event, index){
        event.preventDefault()
        this.transactionTemplate.transactionTemplateCoas.splice(index, 1)
        this.resetData()
      },
      resetData(){
        this.isEdit= false
        this.editIndex= null
        this.coaId= null
        this.recordType= "DR"
      },
    },
    created() {
      this.$emit('onChildInit', 'Add New Transaction Template')
      this.get_all()
    },
    watch: {
      families(nextState, prevState){
        if(nextState !== prevState){
          this.familyOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.familyOptions.push({id:item.id, text:item.familyName})
            })
          }
          if(this.familyOptions.length > 0){
            this.transactionTemplate.familyId = this.familyOptions[0].id
            this.get_coas({familyId: this.transactionTemplate.familyId})
          }
          return
        }
      },
      coas(nextState, prevState){
        if(nextState !== prevState){
          this.coaOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.coaOptions.push({id:item.id, text:item.coaCode + ' - ' + item.coaName, coa: item})
            })
          }          
          this.coaOptions = this.coaOptions.sort(function(a, b){
            if ( a.text < b.text ){
              return -1;
            }
            if ( a.text > b.text ){
              return 1;
            }
            return 0;
          })
          return
        }
      },
    }
  }
</script>
