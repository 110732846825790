<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-3" v-for="cap in capabilities" :key="cap">
        <div class="form-check">
          <input type="checkbox" class="form-check-input" :id="cap.id" v-model="cap.status">
          <label class="form-check-label" :for="cap">{{cap.id}}</label>
        </div>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/users">Cancel</router-link>
      </div>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    computed: {
        ...mapState('user_capabilities', ['user_capability', 'onRequest']),
    },
    data(){
      return {
        capabilities: [],
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        let arr = []
        this.capabilities.map(item => {
          if(item.status == true){
            arr.push(item.id) 
          }
        })
        this.edit({userId: parseInt(this.$route.params.id), userCapabilites: arr})
      },
      ...mapActions('user_capabilities', ['edit', 'get']),
    },
    created() {
      this.$emit('onChildInit', 'Edit User Role')
      this.get(this.$route.params.id)
    }, 
    watch: {
      user_capability(nextState, prevState){
        if(nextState !== prevState){
          this.capabilities = []
          if(nextState.familyCapabilities && nextState.familyCapabilities.length > 0){
            let arr = nextState.familyCapabilities.sort();
            arr.map(item => {
              this.capabilities.push({id:item, status: nextState.userCapabilities.includes(item)})
            })
          }
          return
        }
      },
    }
  }
</script>
