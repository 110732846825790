<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="familyId">Family:</label>
        <Select2 name="familyId" v-model.number="familyId" :options="familyOptions"/>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="currencyId">Currency:</label>
        <Select2 name="currencyId" required v-model.number="currencyId" :options="currencyOptions"/>
      </div>
      <div class="form-group col-sm-12 col-md-6 required">
        <label for="dateStart">From Date:</label>
        <input class="form-control" required type="date" v-model="dateStart">
      </div>
      <div class="form-group col-sm-12 col-md-6 required">
        <label for="dateEnd">To Date:</label>
        <input class="form-control" required type="date" v-model="dateEnd">
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" required type="submit" value="Show" :disabled="this.onRequest">
      </div>
    </div>
  </form>
  <div v-if="this.netProfit" class="table-responsive">
    <table class="table table-borderless">
      <tr v-if="this.datas['REVENUE']"><th colspan="4">REVENUE</th></tr>
      <tr v-for="data in this.datas['REVENUE']" :key="data">
        <td v-if="data.isTotal" class="text-bold">{{data.coaName}}</td>
        <td v-else></td>
        <td v-if="data.isTotal"></td>
        <td v-else>{{data.coaName}}</td>
        <td v-if="data.isTotal" class="text-right text-bold">{{this.formatNumberAcc(data.amount)}}</td>
        <td v-else class="text-right">{{this.formatNumberAcc(data.amount)}}</td>
        <td></td>
      </tr>
      <tr v-if="this.datas['EXPENSE']"><th colspan="4">EXPENSE</th></tr>
      <tr v-for="data in this.datas['EXPENSE']" :key="data">
        <td v-if="data.isTotal" class="text-bold">{{data.coaName}}</td>
        <td v-else></td>
        <td v-if="data.isTotal"></td>
        <td v-else>{{data.coaName}}</td>
        <td v-if="data.isTotal" class="text-right text-bold">{{this.formatNumberAcc(data.amount)}}</td>
        <td v-else class="text-right">{{this.formatNumberAcc(data.amount)}}</td>
        <td></td>
      </tr>
      <tr v-if="this.datas['OTHER_REVENUE']"><th colspan="4">OTHER REVENUE</th></tr>
      <tr v-for="data in this.datas['OTHER_REVENUE']" :key="data">
        <td v-if="data.isTotal" class="text-bold">{{data.coaName}}</td>
        <td v-else></td>
        <td v-if="data.isTotal"></td>
        <td v-else>{{data.coaName}}</td>
        <td v-if="data.isTotal" class="text-right text-bold">{{this.formatNumberAcc(data.amount)}}</td>
        <td v-else class="text-right">{{this.formatNumberAcc(data.amount)}}</td>
        <td></td>
      </tr>
      <tr v-if="this.datas['OTHER_EXPENSE']"><th colspan="4">OTHER EXPENSE</th></tr>
      <tr v-for="data in this.datas['OTHER_EXPENSE']" :key="data">
        <td v-if="data.isTotal" class="text-bold">{{data.coaName}}</td>
        <td v-else></td>
        <td v-if="data.isTotal"></td>
        <td v-else>{{data.coaName}}</td>
        <td v-if="data.isTotal" class="text-right text-bold">{{this.formatNumberAcc(data.amount)}}</td>
        <td v-else class="text-right">{{this.formatNumberAcc(data.amount)}}</td>
        <td></td>
      </tr>
      <tr v-if="this.netProfit"><th colspan="3">{{this.netProfit.name}}</th><th class="text-right">{{this.formatNumberAcc(this.netProfit.amount)}}</th></tr>
    </table>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Select2 from 'vue3-select2-component';
  import moment from 'moment';
  import {formatNumberAcc} from '../_helpers/calculation';
  export default {
    computed: {
      ...mapState('families', ['families']),
      ...mapState('users', ['user', 'profile']),
      ...mapState('reports', ['reports', 'onRequest']),
      ...mapState('currencies', ['currencies']),
    },
    data(){
      return {        
        familyOptions: [],
        currencyOptions: [],
        datas: [],
        netProfit: null,
        familyId: null,
        currencyId: null,
        dateStart: moment().startOf('month').format("YYYY-MM-DD"),
        dateEnd: moment().endOf('month').format("YYYY-MM-DD"),
      }
    },
    components:{
      Select2,
    },
    methods: {
      ...mapActions('reports', {get_data: 'get_income_statement'}),
      ...mapActions('currencies', {get_currencies: 'get_all'}),
      ...mapActions('families', ['get_all']),
      ...mapActions('alert', ['error', 'clear']),
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        this.datas = []
        this.netProfit = null
        const {familyId, dateStart, dateEnd, currencyId} = this
        let start = moment(dateStart).startOf('day').format('x')
        let end = moment(dateEnd).endOf('day').format('x')
        this.get_data({familyId, dateStart: start, dateEnd:end, currencyId})
      },
      formatNumberAcc(x){
        return formatNumberAcc(x, 2)
      },
      summaries(){
        let total = 0;
        total += this.calculate('REVENUE', 'Total Revenue');
        total -= this.calculate('EXPENSE', 'Total Expenses');
        total += this.calculate('OTHER_REVENUE', 'Total Other Income');
        total -= this.calculate('OTHER_EXPENSE', 'Total Other Expenses');
        this.netProfit = {amount: total, name: 'NET PROFIT' + (total > 0 ? '' : ' (LOSS)')}
      },
      calculate(NAME, coaName){        
        let obj = {amount: 0, coaName: coaName, isTotal: true}
        if(this.datas[NAME]){
          this.datas[NAME] = this.datas[NAME].sort(function(a, b){
            if ( a.coaCode < b.coaCode ){
              return -1;
            }
            if ( a.coaCode > b.coaCode ){
              return 1;
            }
            return 0;
          })
          for(let i = 0;i<this.datas[NAME].length;i++){
            obj.amount+=this.datas[NAME][i].amount
          }
          this.datas[NAME].push(obj)
        }
        return obj.amount;
      }
    },
    created() {
      this.$emit('onChildInit', 'Income Statements')
      this.get_all()
      this.get_currencies()
      if(this.profile && this.profile.familyId && this.profile.currencyId){
        this.familyId =  this.profile.familyId
        this.currencyId =  this.profile.currencyId
        this.handleSubmit()
      }
    },
    watch: {
      families(nextState, prevState){
        if(nextState !== prevState){
          this.familyOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.familyOptions.push({id:item.id, text:item.familyName})
            })
          }
          return
        }
      },
      currencies(nextState, prevState){
        if(nextState !== prevState){
          this.currencyOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.currencyOptions.push({id:item.id, text:item.currencyCode + ' - ' + item.currencyName})
            })
          }
          return
        }
      },
      reports(nextState, prevState){
        if(nextState !== prevState){
          if(nextState.length > 0){
            this.datas = []
            nextState.map(item => {
              if(!this.datas[item.coaCategory]){
                this.datas[item.coaCategory] = []
              }
              this.datas[item.coaCategory].push(item)
            })
            this.summaries()
          }
          return
        }
      },
    }
  }
</script>
